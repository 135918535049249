import CoronaCrisis from "components/coronaCrisis";
import Layout from "components/layout";
import SEO from "components/seo";
import React from "react";

const index = () => {
  const title = "Coronavirus Crisis Communication Center | Tomedes";
  const description =
    "Crisis Communication Language Solutions for global enterprises 24/7. We provide professional translation services, localization and interpretation services in more than 80 languages.";
  const keywords = "content";
  const slug = "/crisis-communication-center";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={slug}
      />
      <CoronaCrisis />
    </Layout>
  );
};

export default index;
