import React from "react";

const CoronaBlock3 = () => {
  return (
    <div className="bg-midBlue py-16 my-[-1px]">
      <div className="w-11/12 flex flex-col justify-center mx-auto">
        <div className="flex mx-auto sm:flex-row flex-col relative pt-36 justify-between">
          <div className="w-full sm:w-[45%]">
            <h4 className="text-white font-secondary text-[32px]">
              Healthcare
            </h4>
            <p className="text-white leading-loose pt-[2rem]">
              Among the most urgent needs in the crisis are for rapid medical
              translation and medical interpretation. This is essential for
              healthcare providers, researchers, and public health officials.
              This includes the multilingual preparation of papers,
              presentations, reports and collateral. Delivery of Over the Phone
              and Remote Video Interpretation – simultaneous and consecutive –
              is in exceptionally high demand. Transcription services are also
              required to transform audio and video content into more practical
              text summaries. Tomedes can help you deploy a remote
              interpretation platform (phone / video) or deliver on-demand
              services for translators, transcribers, and interpreters as
              required.
            </p>
          </div>
          <div className="w-full sm:w-[45%] pt-[2rem]">
            <img
              src="https://skins.tomedes.com/frontend/images/crisis/services/healthcare.png"
              alt=""
            />
          </div>
          <button className="text-white font-semibold bg-orange rounded-full left-[18px] sm:left-0 mt-6 px-5 py-2 absolute bottom-[-48px]">
            Read More
          </button>
        </div>
        <div className="flex justify-between sm:flex-row flex-col  pt-36">
          <div className="w-full sm:w-[45%] pt-[2rem]">
            <img
              src="https://skins.tomedes.com/frontend/images/crisis/services/ecommerce.png"
              alt=""
            />
          </div>
          <div className="w-full sm:w-[45%]">
            <h4 className="text-white font-secondary text-[32px]">Ecommerce</h4>
            <p className="text-white leading-loose pt-[2rem]">
              The Coronavirus Crisis has increased the importance of electronic
              commerce and expanded potential markets. But with this shift has
              come a growing need for multilingual communication. This includes
              urgent communications with vendors and distributors in foreign
              countries as well as the pressing need for expanded customer
              service support in various languages to cope with disruptions,
              complaints, explanations and retention efforts. Multilingual
              websites and apps need to be more frequently updated to cope with
              the fast-changing situation. Tomedes brings 13 years of global
              experience to assist its commerce partners with quick-turn
              translation and localization projects in more than 100 languages.
            </p>
          </div>
        </div>
        <div className="flex mx-auto sm:flex-row flex-col relative pt-36 justify-between">
          <div className="w-full sm:w-[45%]">
            <h4 className="text-white font-secondary text-[32px]">Financial</h4>
            <p className="text-white leading-loose pt-[2rem]">
              The world economy is in flux, and the great uncertainty places a
              heavy burden on all those involve in financial activity. This is
              true for banking and brokerage, accounting and insurance. It is
              true for policy makers and those who implement that policy. And it
              is certainly true for all those responsible for reporting this
              information to multilingual stakeholders, businesses and the
              general public. In a global pandemic, the need for communications
              is international. Even for domestic communications, we live in
              culturally diverse locations with citizens who speak different
              languages and need to understand fast-changing financial and
              economic policy and directives. Fast turn document translation is
              essential, as is Over-the-Phone, remote video interpretation, and
              transcription for events and meetings
            </p>
          </div>
          <div className="w-full sm:w-[45%] pt-[2rem]">
            <img
              src="https://skins.tomedes.com/frontend/images/crisis/services/financial.png"
              alt=""
            />
          </div>
          <button className="text-white font-semibold bg-orange rounded-full mt-6 px-5 py-2 left-[18px] sm:left-0 absolute bottom-[-48px]">
            Read More
          </button>
        </div>
        <div className="flex justify-between sm:flex-row flex-col  pt-36">
          <div className="w-full sm:w-[45%] pt-[2rem]">
            <img
              src="https://skins.tomedes.com/frontend/images/crisis/services/ecommerce.png"
              alt=""
            />
          </div>
          <div className="w-full sm:w-[45%]">
            <h4 className="text-white font-secondary text-[32px]">
              Social Services
            </h4>
            <p className="text-white leading-loose pt-[2rem]">
              Citizens around the world are in distress, and the nature of the
              global pandemic has imposed the necessity of social distancing.
              This compels tremendous changes in our society and pushes
              face-to-face communications into the virtual realm. Increasingly,
              citizens – especially those most at risk -- will need to receive
              services not facing another person in physical space but via a
              computer screen. To communicate remotely, those who speak a
              minority language require online, on-demand language support from
              service providers. Tomedes manages geographically dispersed
              linguistic teams to deliver local solutions. We apply AI-driven
              technology and deploy online multilingual services, with remote
              phone and video interpreters and translators – on call where and
              when they are needed.
            </p>
          </div>
        </div>
        <div className="flex justify-between sm:flex-row flex-col  pt-36">
          <div className="w-full sm:w-[45%]">
            <h4 className="text-white font-secondary text-[32px]">
              Small Businesses{" "}
            </h4>
            <p className="text-white leading-loose pt-[2rem]">
              Small and medium enterprises bear the brunt of the global
              pandemic. They are less equipped to endure sustained shutdowns,
              supply and service disruptions. They are more affected by
              employees compelled to work from home. To survive, they need to
              keep communicating with globally dispersed suppliers, partners,
              and customers under conditions of severe disruption. To cope, many
              SMEs are seeking teleworking solutions to keep their staff
              productive and effective. Tomedes brings 13 years of experience in
              managing communications across linguistic borders, remotely and
              efficiently. Whether the need is for quick-turn document
              translation, Over-the-Phone interpretation, or setting up
              teleworking and videoconference solutions for multilingual
              workforces, we help small businesses weather the storm.
            </p>
          </div>
          <div className="w-full sm:w-[45%] pt-[2rem]">
            <img
              src="https://skins.tomedes.com/frontend/images/crisis/services/small-businesses.png"
              alt=""
            />
          </div>
        </div>
        <div className="flex justify-between sm:flex-row flex-col  pt-36">
          <div className="w-full sm:w-[45%] pt-[2rem]">
            <img
              src="https://skins.tomedes.com/frontend/images/crisis/services/travel.png"
              alt=""
            />
          </div>
          <div className="w-full sm:w-[45%]">
            <h4 className="text-white font-secondary text-[32px]">
              Travel Industry{" "}
            </h4>
            <p className="text-white leading-loose pt-[2rem]">
              Global travel and hospitality industries have been battered, but
              their battle is just beginning. On the horizon is a sustained
              effort to serve and retain customers across the world, and to
              rebuild service offerings. That means localized systems and
              multilingual resources in place to deal with the surge of service
              requests from customers whose flights and reservations have been
              canceled. Multilingual messaging via websites, apps, and emails
              needs to be updated constantly. Tomedes brings more than a decade
              of experience delivering such solutions remotely, and on short
              notice. Our services include language support for call centers and
              quick-turn assistance communicating with foreign language partners
              and customers. We can provide over the phone and remote video
              interpretation or help set up ongoing teleworking and
              videoconferencing solutions.
            </p>
          </div>
        </div>
        <div className="flex justify-between sm:flex-row flex-col  pt-36">
          <div className="w-full sm:w-[45%]">
            <h4 className="text-white font-secondary text-[32px]">
              Media and Entertainment
            </h4>
            <p className="text-white leading-loose pt-[2rem]">
              More than ever before, the world’s citizens, many home-bound,
              depend on media to inform and entertain us during this crisis.
              Increasingly, as established companies are affected by
              quarantines, lockdowns, and social distancing restrictions, media
              producers must seek alternative methods to get their messages out.
              Ironically, the global pandemic has brought us together in a
              shared experience that transcends borders and language barriers.
              But with this remarkable development has come a greater need for
              methods to overcome language differences. The Tomedes crisis
              taskforce is intensively engaged in helping our partners and
              clients, providing services and resource to expand your reach
              across the world with on-demand phone and video interpreters,
              transcription and translation services.
            </p>
          </div>
          <div className="w-full sm:w-[45%] pt-[2rem]">
            <img
              src="https://skins.tomedes.com/frontend/images/crisis/services/media.png"
              alt=""
            />
          </div>
        </div>
        <div className="flex mx-auto sm:flex-row flex-col relative pt-36 justify-between">
          <div className="w-full sm:w-[45%] pt-[2rem]">
            <img
              src="https://skins.tomedes.com/frontend/images/crisis/services/financial.png"
              alt=""
            />
          </div>
          <div className="w-full sm:w-[45%]">
            <h4 className="text-white font-secondary text-[32px]">
              Technology Services
            </h4>
            <p className="text-white leading-loose pt-[2rem]">
              Technology companies are more prepared than others in familiarity
              with teleworking and other virtual services. They recognize the
              importance of software localization, in websites and applications,
              to reach dispersed B2B customers and B2C consumers online. The
              need to overcome language and national borders has never been
              greater, and we’ve set up our crisis taskforce to assist tech
              firms in continuing and expanding their teleworking and virtual
              outreach to their markets and target audiences. Our multilingual
              support team is fully activated and engaged to provide quick turn,
              on demand services across the communications spectrum:
              transcription and translation services, video and phone
              interpreters, localization of websites and mobile apps – whatever
              you need, when you need it.
            </p>
          </div>

          <button className="text-white font-semibold bg-orange rounded-full mt-6 px-5 py-2 absolute sm:right-[26rem] bottom-[-48px]">
            Read More
          </button>
        </div>
        <div className="flex justify-between sm:flex-row flex-col  pt-36">
          <div className="w-full sm:w-[45%]">
            <h4 className="text-white font-secondary text-[32px]">
              E-learning Services
            </h4>
            <p className="text-white leading-loose pt-[2rem]">
              As schools and university close or transition from traditional
              education to distance learning methods, there’s a huge demand for
              optimized services and solutions to link educators and students to
              ensure continuity. Tomedes has long been in the business of
              elearning and teleworking, networking worldwide teams of skilled
              linguists to deliver on-demand, quick-terms solutions to our tens
              of thousands of clients. Our crisis taskforce is ready and
              well-equipped to help deploy distance learning and cross-language
              communications solutions to schools and universities, especially
              those which require multilingual support. Whether your
              organization require videoconference platforms, phone or video
              interpreters, transcription or translation services, our expert
              linguists and technologists are on call to service you.
            </p>
          </div>
          <div className="w-full sm:w-[45%] pt-[2rem]">
            <img
              src="https://skins.tomedes.com/frontend/images/crisis/services/elearning.png"
              alt=""
            />
          </div>
        </div>
        <div className="flex justify-between sm:flex-row flex-col  pt-36">
          <div className="w-full sm:w-[45%] pt-[2rem]">
            <img
              src="https://skins.tomedes.com/frontend/images/crisis/services/security.png"
              alt=""
            />
          </div>
          <div className="w-full sm:w-[45%]">
            <h4 className="text-white font-secondary text-[32px]">
              Security and Public Safety{" "}
            </h4>
            <p className="text-white leading-loose pt-[2rem]">
              Governments and organization every level seek urgently to develop
              systems and solutions to safeguard the general public, healthcare
              providers, first responders, and vulnerable populations. The
              Tomedes crisis taskforce has been set up to respond immediately to
              these needs with a focus on deploying communications and language
              services that overcome language barriers. Our global teams are
              fully engaged to support effective outreach and engagement of
              culturally and linguistically diverse populations. On short
              notice, we can deliver translation services for websites, apps,
              and documents as well as real-time or recorded interpretation and
              transcription solutions, including phone and video interpreters to
              accompany briefings and events. Supporting more than 100
              languages, we can deliver multilingual solutions for every
              dimension of public and health security.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoronaBlock3;
