import React from "react";

const CoronaBlock2 = () => {
  return (
    <div className="bg-darkBlue py-12 px-5">
      <div className="w-full mx-auto">
        <div className="flex flex-col justify-center items-center leading-loose sm:w-[93%] mx-auto">
          <p className="text-center text-white text-[24px] font-sans">
            In the current crisis, with its severe disruption of “business as
            usual”, we have set up a crisis taskforce to support companies who
            need to communicate with external customers, stakeholders and their
            own dispersed staffs, many working from home.
          </p>
          <button className="rounded-full bg-orange text-[18px] text-white px-5 mt-3 py-1">
            Get Started
          </button>
        </div>
        <div className="flex sm:flex-row flex-col justify-between gap-x-7 pt-24 gap-y-8">
          <div className="sm:w-[50%]">
            <p className="text-[20px] sm:text-left text-center font-sans text-white">
              We are uniquely positioned to deliver urgent services. Our
              “virtual” communications agency deploys geographically dispersed
              teams across borders and time zones, connecting them efficiently
              with customers and each other. Translators, transcribers, phone
              and video interpreters are on call for you.
            </p>
            <p className="text-[20px] sm:text-left text-center font-sans text-white pt-7">
              Throughout this crisis, on this page, we will be sharing that
              acquired wisdom, useful resources, and actionable best practices
              to help our partners and customers pivot to recovery. Here we will
              impart the best of what we know, so all of us can get through this
              time together and emerge stronger than ever.
            </p>
          </div>
          <div className="sm:w-[50%]">
            <img
              src="https://skins.tomedes.com/frontend/images/crisis/building1.png"
              alt=""
              // className="sm:w-[595px] h-[390px]"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center mx-auto pt-24">
          <h3 className="text-[36px] text-white font-secondary text-center">
            At Your Service from Crisis through Recovery
          </h3>
          <p className="text-[18px] font-sans text-white text-center pt-6">
            Tomedes delivers quick-turn language and support services you need
            in these critical times:
          </p>
          <div className="grid sm:grid-row-1 md:grid-cols-4 pt-24 justify-items-center gap-y-4 gap-x-3">
            <div className="rounded bg-black px-8 py-6 min-h-[385px] max-w-[18rem] sm:max-w-[16rem] relative">
              <img
                src="https://skins.tomedes.com/frontend/images/crisis/icon1.png"
                alt=""
              />
              <h4 className="text-white py-6 font-semibold">
                Phone & Video Remote Interpretation
              </h4>
              <p className="text-white ">
                Interpreting remotely over the phone or via videoconference so
                you can communicate accurately with foreign language speakers
              </p>
              <img
                src="https://skins.tomedes.com/frontend/images/crisis/arrow-right.png"
                alt=""
                className="absolute right-[32px] sm:right-[22px] pt-8 sm:pt-2"
              />
            </div>
            <div className="rounded bg-black px-8 py-6 min-h-[385px] max-w-[18rem] sm:max-w-[16rem] relative">
              <img
                src="https://skins.tomedes.com/frontend/images/crisis/icon2.png"
                alt=""
              />
              <h4 className="text-white py-6 font-semibold">
                24/7 <br />
                Customer Service
              </h4>
              <p className="text-white ">
                Responding to and supporting in multiple languages your
                international customers when they call, text, or email
              </p>
              <img
                src="https://skins.tomedes.com/frontend/images/crisis/arrow-right.png"
                alt=""
                className="absolute right-[32px] sm:right-[22px] pt-12 sm:pt-2"
              />
            </div>{" "}
            <div className="rounded bg-black px-8 py-6 min-h-[385px] max-w-[18rem] sm:max-w-[16rem] relative">
              <img
                src="https://skins.tomedes.com/frontend/images/crisis/icon3.png"
                alt=""
              />
              <h4 className="text-white py-6 font-semibold">
                Translation & <br />
                Localization
              </h4>
              <p className="text-white ">
                Translating documents, presentations, and websites as well as
                localizing software applications and social media for new
                markets
              </p>
              <img
                src="https://skins.tomedes.com/frontend/images/crisis/arrow-right.png"
                alt=""
                className="absolute right-[32px] sm:right-[22px] pt-8 sm:pt-2"
              />
            </div>{" "}
            <div className="rounded bg-black px-8 py-6 min-h-[385px] max-w-[18rem] sm:max-w-[16rem] relative">
              <img
                src="https://skins.tomedes.com/frontend/images/crisis/icon4.png"
                alt=""
              />
              <h4 className="text-white py-6 font-semibold">Transcreation</h4>
              <p className="text-white ">
                Adapting content so that it accurately conveys your intent,
                style, tone, and context across linguistic and cultural borders
              </p>
              <img
                src="https://skins.tomedes.com/frontend/images/crisis/arrow-right.png"
                alt=""
                className="absolute right-[32px] sm:right-[22px] sm:mt-[50px] mt-[70px] "
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 pt-[3rem] justify-items-center gap-y-4">
          <div className="p-8 bg-black rounded w-[22rem] sm:w-[34rem]">
            <h4 className="text-white text-[30px] text-semibold font-secondary">
              Internal Communications
            </h4>
            <p className="text-white leading-loose text-[16px] pt-8">
              Here are some of the tools and techniques we use to connect
              dispersed work teams. Telework is the way we have been doing
              business for more than a decade, and we will share the best
              practices and resources we have learned along the way to keep your
              teams responsive, motivated, and productive.
            </p>
          </div>
          <div className="p-8 bg-black rounded w-[22rem] sm:w-[34rem]">
            <h4 className="text-white text-[30px] text-semibold font-secondary">
              Internal Communications
            </h4>
            <p className="text-white leading-loose text-[16px] pt-8">
              Here are some of the tools and techniques we use to connect
              dispersed work teams. Telework is the way we have been doing
              business for more than a decade, and we will share the best
              practices and resources we have learned along the way to keep your
              teams responsive, motivated, and productive.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoronaBlock2;
