import React from "react";

const CoronaBlock1 = () => {
  return (
    <div className="bg-midBlue pt-28 pb-96 mb-[-1px]">
      <div className="w-full sm:w-[65%] flex justify-center">
        <div className=" w-full  max-w-max h-4 sm:h-4 md:h-6 lg:h-9">
          <h1 className=" text-xl md:text-5xl lg:text-6xl sm:text-3xl font-medium  font-riot text-white">
            Coronavirus Crisis <br /> Communication Center
          </h1>
          <p className="text-[22px] text-white font-normal py-3">
            Language Services and Resources for Transitioning Through Recovery
          </p>

          <button
            href=""
            className="bg-[#F26521] text-white  text-[24px] rounded-full py-3 px-6 mt-8"
          >
            Contact Sales
          </button>
          <p className="text-[22px] text-white font-normal py-3">
            You can call us directly at +1 985 239 0142
          </p>
        </div>
      </div>
    </div>
  );
};

export default CoronaBlock1;
