import React from "react";
import CoronaBlock1 from "./Block1";
import CoronaBlock2 from "./Block2";
import CoronaBlock3 from "./Block3";

const CoronaCrisis = () => {
  return (
    <>
    <CoronaBlock1/>
    <CoronaBlock2/>
    <CoronaBlock3/>
    </>
  );
};

export default CoronaCrisis;
